import React from 'react'
import { useEffect, useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function CarouselComponent() {

//     const [data,setData] = useState({})
//   const [event,setEvent] = useState({})
//   const [isLoading, setLoading] = useState(true);

//   useEffect(() =>{

//   const getPhotos =  () =>{

//     require("isomorphic-fetch") // or another library of choice.

//     var Dropbox = require("dropbox").Dropbox
//     var dbx = new Dropbox({
//       accessToken:
//         "sl.BNs3jQojyY5swlNUNtLJn1WOvygIvD4a8QgM8rLXQ9D2UQ6tsMYhIyySUWRCY_nzR4hqC1HqmoCbnyThq7dfHyqQdpV04_QLo0oT7Ojxd7CCJI-f8BO_xL104qysDISFCe3PWkqQWGQ",
//     })
//     // dbx.filesListFolder({path: '/Apps/rl-gallery'})
//     //   .then(function(response) {
//     //     console.log(response);
//     //   })
//     //   .catch(function(error) {
//     //     console.log(error);
//     //   });
//     dbx.filesListFolder({ path: "" }).then((response) => {
//       //console.log(response.result.entries)
//       const results = response.result.entries
//       console.log(results)
//       results.forEach((d) => {
//         dbx
//           .filesGetTemporaryLink({ path: d.path_lower })
//           .then((response) => {
//               const embeds = response.result.link
//               console.log(response.result.link)})
//               setData(response.result.link);
//               setLoading(false);
//       })
//     })
    

    
    
//   }
//   getPhotos()
  
// },[])

    
    return (
        <div className='container mx-auto h-96'>
        <div className="carousel-wrapper">
            {/* <Carousel width="800px" dynamicHeight="true"> */}
            <Carousel  dynamicHeight="true">
{/* 
            {data.map((item,key) => {
                    return ( */}
                <div>
                    <img src="https://dl.dropbox.com/s/ear8zquwd34avyr/IMG_20220727_083458_00_037.jpg" />
                </div>
                <div>
                    <img src="https://dl.dropbox.com/s/9q1900i1rrkdqp4/Photo%20Jul%2027%2C%201%2001%2039%20PM.jpg" />
                </div>
                <div>
                    <img src="https://dl.dropbox.com/s/kyot00bx3yjql9b/Photo%20Jul%2027%2C%208%2034%2028%20PM.jpg" />
                </div>
                <div>
                    <img src="https://dl.dropbox.com/s/1sr05t1tk0nkdzp/Photo%20Jul%2028%2C%204%2021%2047%20AM.jpg" />
                </div>
                <div>
                    <img src="https://dl.dropbox.com/s/qkthouh4ai6r77v/Photo%20Jul%2028%2C%206%2000%2011%20PM.jpg" />
                </div>
                <div>
                    <img src="https://dl.dropbox.com/s/snpemkr9la85k86/Photo%20Jul%2028%2C%209%2026%2051%20AM.jpg" />
                </div>
                <div>
                    <img src="https://dl.dropbox.com/s/2ien88czn2s9g3r/Photo%20Jul%2029%2C%208%2022%2059%20AM.jpg" />
                </div>
                <div>
                    <img src="https://dl.dropbox.com/s/x23y4m79yjxss1g/Photo%20Jul%2029%2C%209%2020%2051%20PM.jpg" />
                </div>
                <div>
                    <img src="https://dl.dropbox.com/s/oyhwl6109ua74fj/Photo%20Jul%2029%2C%2012%2015%2043%20PM.jpg" />
                </div>
                <div>
                    <img src="https://dl.dropbox.com/s/9fyagageowcunj2/Photo%20Jul%2030%2C%206%2003%2030%20PM.jpg" />
                </div>
                <div>
                    <img src="https://dl.dropbox.com/s/hnwfvqf3767temc/Photo%20Jul%2030%2C%2012%2050%2026%20PM.jpg" />
                </div>
                <div>
                    <img src="https://dl.dropbox.com/s/3v9l1ygz2j3m90c/Photo%20Jul%2030%2C%206%2009%2029%20PM.jpg" />
                </div>

                {/* // <div>
                //     {console.log(item)}
                //     <img src={item}/>
                // </div>
                //     )})} */}
            </Carousel>
        </div>
        </div>
    );
}
