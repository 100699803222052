import React from 'react'
import { useEffect, useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function CarouselComponentDynamic() {

//     const [data,setData] = useState({})
//   const [event,setEvent] = useState({})
//   const [isLoading, setLoading] = useState(true);

//   useEffect(() =>{

//   const getPhotos =  () =>{

//     require("isomorphic-fetch") // or another library of choice.

//     var Dropbox = require("dropbox").Dropbox
//     var dbx = new Dropbox({
//       accessToken:
//         "sl.BNs3jQojyY5swlNUNtLJn1WOvygIvD4a8QgM8rLXQ9D2UQ6tsMYhIyySUWRCY_nzR4hqC1HqmoCbnyThq7dfHyqQdpV04_QLo0oT7Ojxd7CCJI-f8BO_xL104qysDISFCe3PWkqQWGQ",
//     })
//     // dbx.filesListFolder({path: '/Apps/rl-gallery'})
//     //   .then(function(response) {
//     //     console.log(response);
//     //   })
//     //   .catch(function(error) {
//     //     console.log(error);
//     //   });
//     dbx.filesListFolder({ path: "" }).then((response) => {
//       //console.log(response.result.entries)
//       const results = response.result.entries
//       console.log(results)
//       results.forEach((d) => {
//         dbx
//           .filesGetTemporaryLink({ path: d.path_lower })
//           .then((response) => {
//               const embeds = response.result.link
//               console.log(response.result.link)})
//               setData(response.result.link);
//               setLoading(false);
//       })
//     })
    

    
    
//   }
//   getPhotos()
  
// },[])
    var photos =[
        "https://dl.dropbox.com/s/ear8zquwd34avyr/IMG_20220727_083458_00_037.jpg",
        "https://dl.dropbox.com/s/9q1900i1rrkdqp4/Photo%20Jul%2027%2C%201%2001%2039%20PM.jpg",
        "https://dl.dropbox.com/s/kyot00bx3yjql9b/Photo%20Jul%2027%2C%208%2034%2028%20PM.jpg"
    ]
    
    return (
        <div className='container mx-auto h-96'>
        <div className="carousel-wrapper">
            {/* <Carousel width="800px" dynamicHeight="true"> */}
            <Carousel  dynamicHeight="true">
 
            {photos.map((item,key) => {
                    return ( 
                <div>
                    <img src={item}/>
                </div>

                )})}



            </Carousel>
        </div>
        </div>
    );
}
