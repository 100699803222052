import React from 'react'
import Pano from '../components/Pano/Pano'
//import Pano2 from '../components/Pano/Pano2'

function LondonPanos() {
  return (
    <div className='ml-10 mt-5'>
      <div className='h1 text-xl font-bold'>London Panoramas</div>


        <div className='h2 text-med font-bold mt-10 mb-5'>360 pano</div>
        <div>
          <Pano
            test={
              'https://dl.dropbox.com/s/ybadkbsvsk18fht/IMG_20220727_093245_00_merged.jpg'
            }
          />
        <div>
          <Pano
            test={
              'https://dl.dropbox.com/s/0bixfy52vobl35m/IMG_20220728_110925_00_merged.jpg'
            }
          />
        </div>

        <div>
          <Pano
            test={
              'https://dl.dropbox.com/s/1d28c0qkn1apllx/IMG_20220728_094110_00_merged.jpg'
            }
          />
        </div>
        {/* <Pano2/> */}
      </div>
    </div>
  )
}

export default LondonPanos
