import React from 'react'
// import Pano from '../components/Pano/Pano'
// import Pano2 from '../components/Pano/Pano2'

function Tattoo() {
  return (
    <div className='ml-10 mt-5'>
      <div className='h1 text-xl font-bold'>Tattoo Festival</div>
      <div className='h4 text-sm'>Visited August 4,2022</div>
      <div className='h2 text-med font-bold mt-5'>Edinburgh Castle</div>
      <div className='flex flex-wrap'>

        <div className='flex-initial w-2/4 mr-5'>
          <p>
            Tattoo.
          </p>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto est debitis voluptatibus recusandae fugit eius aliquam illum fugiat enim temporibus nihil, reprehenderit laudantium explicabo officia, ratione nesciunt omnis id fuga magnam voluptas. Optio tempora inventore voluptatem debitis in numquam?</p>
        </div>

        <div className='flex-none'>
          <img
            className='w-100 h-60 rounded-lg'
            src='https://dl.dropbox.com/s/b3oac4dq270069w/VID_20220804_230949_00_879_2022-09-02_16-35-38_screenshot.jpg'
            alt=''
          />
        </div>


      </div>
      <div className='mt-5'>
      <video controls>
            <source src='https://dl.dropbox.com/s/buzr8dcu6nwl8zc/VID_20220804_230949_00_879.mp4'></source>
          </video>
          </div>

      {/* <div className='h2 text-med font-bold mt-10'>Yeoman Guard</div>
      <div className='flex flex-wrap mb-5 '>
        <div className='flex-initial w-64'>
          <video controls>
            <source src='https://www.dropbox.com/s/buzr8dcu6nwl8zc/VID_20220804_230949_00_879.mp4?dl=0'></source>
          </video>
            className='w-64 h-80 rounded-lg'
            src='https://dl.dropbox.com/s/3d3cfimuvud1hho/DSCF5547.jpg'
            alt=''
          />
        </div>
        <div className='flex-initial w-2/4 ml-5'>
          <p>
            Evan with a Beefeater-Yeoman Warders, also known as ‘Beefeaters’
          </p>
        </div>
      </div>
      {/* <div className='h-100 w-100'> */}

      </div>

  )
}

export default Tattoo
