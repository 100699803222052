import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { defaultIcon } from "../icons/defaultIcon";
import { places } from "../data/places";

const MarkerLayer = ({data}) => {


  return data.features.map((feature) => {

    const { coordinates} = feature.geometry; 
    const { name, adm0name } = feature.properties;
    {console.log(coordinates)}
    return (
    <Marker key={String(coordinates)} position={[coordinates[0],coordinates[1]]} icon={defaultIcon}>
      <Popup>
        {name},{adm0name}<br /> 
      </Popup>
    </Marker>
    )
  })
}


export const Map = () => {
  const position = [53.441144493163705, -1.9884948548344406];

  const scrollWheelZoom = true;
  console.log("scrollwheelzoom", scrollWheelZoom);

  return (
    <MapContainer center={position} zoom={5} scrollWheelZoom={scrollWheelZoom}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkerLayer data={places} />
    </MapContainer>
  );
};
