import "./styles.css";
import React,{ useRef, useEffect } from "react";
import * as PANOLENS from "panolens";

export default function Pano({test}) {
  const reff = useRef();
  console.log(test)




  useEffect(() => {
    var panorama, viewer, infospot, infospot2, imgg;
    panorama = new PANOLENS.ImagePanorama(
      test
    );
    // panorama = new PANOLENS.ImagePanorama(
    //   "https://i.ibb.co/dmLZY05/Street-View-3620.jpg"
    // );
    console.log(test)
    viewer = new PANOLENS.Viewer({
      container: reff.current
    });

    viewer.add(panorama);
  }, []);

  return <div className="object-contain h-96 w-128" ref={reff}></div>;
  // return <div id="pano" htmlstyle="width:50%" ref={reff}></div>;
}
