import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes,Link } from 'react-router-dom'
import Header from './components/Header';
import Place from './components/Place'
import Data from './data.json'
import CarouselComponent from './components/CarouselComponent';
import CarouselComponentDynamic from './components/CarouselComponentDynamic';
import DropBoxImage from './components/DropBoxImage';
import TowerLondon from './pages/TowerLondon';
import LondonPanos from './pages/LondonPanos';
import Tattoo from './pages/Tattoo';
import Navbar from './components/Navbar';


function App() {
  const [location, setLocation] = useState(Data);
  return (
    <Router>

    <div className="App">
      <Navbar />

      {/* <DropBoxImage /> */}
      <Routes>

      {/* <Place item={location} /> */}
      <Route path='/' element={<Place item={location} />} />
      <Route path='/tower' element={<TowerLondon />} />
      <Route path='/london' element={<LondonPanos />} />
      <Route path='/tattoo' element={<Tattoo />} />
      <Route path='/photos' element={<CarouselComponent />}/>
      <Route path='/photos2' element={<CarouselComponentDynamic />}/>
      </Routes>
    </div>

    </Router>
  );
}

export default App;
