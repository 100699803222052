export const places = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        name: "Stay Central",
        nameascii: "K&K",
        city: "Edinborgh",
        sov0name: "UK",
        info: "Hotel",
        latitude: 0.58329910562,
        longitude: 32.5332995249,
        featureclass: "Admin-1 region capital",
      },
      geometry: {
        type: "Point",
        coordinates: [55.94878684319163, -3.1903116214150975],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "K&K Hotel",
        nameascii: "Stay Central",
        city: "London",
        sov0name: "UK",
        info: "Hotel",
        latitude: 0.58329910562,
        longitude: 32.5332995249,
        featureclass: "Admin-1 region capital",
      },
      geometry: {
        type: "Point",
        coordinates: [51.49257241837626, -0.19556191678430407],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "Roman Baths ",
        nameascii: "Stay Central",
        city: "Bath",
        sov0name: "UK",
        info: "Siteseeing",
        latitude: 0.58329910562,
        longitude: 32.5332995249,
        featureclass: "Admin-1 region capital",
      },
      geometry: {
        type: "Point",
        coordinates: [51.38197178924568, -2.3586354884389915],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "Windsor Castle",
        nameascii: "Windsor Castle",
        city: "Bath",
        sov0name: "UK",
        info: "Siteseeing",
        latitude: 0.58329910562,
        longitude: 32.5332995249,
        featureclass: "Admin-1 region capital",
      },
      geometry: {
        type: "Point",
        coordinates: [51.48407437214335, -0.6044670744559232],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "Doune Castle",
        nameascii: "Doune Castle",
        city: "Bath",
        sov0name: "UK",
        info: "Siteseeing",
        latitude: 0.58329910562,
        longitude: 32.5332995249,
        featureclass: "Admin-1 region capital",
      },
      geometry: {
        type: "Point",
        coordinates: [56.29496154912855, -4.019063549206643],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "Blackness Castle",
        nameascii: "Blackness Castle",
        city: "Bath",
        sov0name: "UK",
        info: "Siteseeing",
        latitude: 0.58329910562,
        longitude: 32.5332995249,
        featureclass: "Admin-1 region capital",
      },
      geometry: {
        type: "Point",
        coordinates: [56.00619178974832, -3.515999949462999],
      },
    },
    
    
  ],
};
