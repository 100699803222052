import React from 'react'
import Pano from '../components/Pano/Pano'
//import Pano2 from '../components/Pano/Pano2'

function TowerLondon() {
  return (
    <div className='ml-10 mt-5'>
      <div className='h1 text-xl font-bold'>Tower of London</div>
      <div className='h4 text-sm'>Visited July 28,2022</div>
      <div className='h2 text-med font-bold mt-5'>White Tower</div>
      <div className='flex flex-wrap'>

        <div className='flex-initial w-2/4 mr-5'>
          <p>
            White Tower-The White Tower is a central tower, the old keep, at the
            Tower of London. It was built by William the Conqueror during the
            early 1080s, and subsequently extended.
          </p>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto est debitis voluptatibus recusandae fugit eius aliquam illum fugiat enim temporibus nihil, reprehenderit laudantium explicabo officia, ratione nesciunt omnis id fuga magnam voluptas. Optio tempora inventore voluptatem debitis in numquam?</p>
        </div>

        <div className='flex-none'>
          <img
            className='w-64 h-80 rounded-lg'
            src='https://dl.dropbox.com/s/kbz2769jnuqs2q5/DSCF5538-2.jpg'
            alt=''
          />
        </div>

      </div>

      <div className='h2 text-med font-bold mt-10'>Yeoman Guard</div>
      <div className='flex flex-wrap mb-5 '>
        <div className='flex-initial w-64'>
          <img
            className='w-64 h-80 rounded-lg'
            src='https://dl.dropbox.com/s/3d3cfimuvud1hho/DSCF5547.jpg'
            alt=''
          />
        </div>
        <div className='flex-initial w-2/4 ml-5'>
          <p>
            Evan with a Beefeater-Yeoman Warders, also known as ‘Beefeaters’
          </p>
        </div>
      </div>
      <div className='container mx-auto'>
      <div className='h2 text-med font-bold mt-10 mb-5'>360 pano</div>
      <Pano test={"https://dl.dropbox.com/s/0bixfy52vobl35m/IMG_20220728_110925_00_merged.jpg"}/>
       
       {/* <Pano2/> */}
       </div>
      </div>

  )
}

export default TowerLondon
